import { Component } from '@angular/core';
import { SignInService } from './auth/sign-in.service';
import { UtilityService } from './core/services/utility.service';
import { Router } from '@angular/router';
import { AuthorizationService } from './core/services/authorization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'shiksha-frontend';

  /**
   * Class constructor
   * @param authService SignInService
   * @param utilityService UtilityService
   * @param router Router
   */
  constructor(
    private authService: SignInService,
    private utilityService: UtilityService,
    private router: Router,
    private authorizationService: AuthorizationService
  ) {}

  /**
   * Angular ngonint lifecycle hook
   */
  ngOnInit(): void {
    if (this.authorizationService.isLoggedIn()) {
      this.updateUserData();
    }
  }

  /**
   * Method to update latest user data
   */
  updateUserData() {
    this.authService.authMe().subscribe({
      next: (res: any) => {
        localStorage.setItem('userData', JSON.stringify(res?.data));
      },
      error: (err: any) => {
        this.utilityService.handleError(err);
      },
    });
  }
}
